import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import useCutterProfiles from '@hooks/useCutterProfiles'
import { generateUrls } from '@hooks/useCutterProfile'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import CutterProfileListItem from '../CutterProfileListItem'

import type { PageContextPayload } from '../../index'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by Cutter Profile name or ID',
        style,
      },
      key: 'string',
      label: 'Name or ID',
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const cutterProfilesPayload = useCutterProfiles({
    filters: { ...pageFilters },
    performHttpRequests: true,
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCutterProfiles,
    filteredCutterProfilesCount,
    hasFilteredCutterProfiles,
    loading,
  } = cutterProfilesPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      showCreateOrEditCutterProfileModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const { cutterProfilesIndexUrl } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Cutter Profiles | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateOrEditCutterProfileModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey="cutterProfiles"
        tabBarItems={[
          {
            href: cutterProfilesIndexUrl,
            icon: faCheckCircle,
            key: 'cutterProfiles',
            title: 'Cutter Profiles',
          },
        ]}
        title="Cutter Profiles"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredCutterProfiles && <ListBlankState />}

            {!loading && hasFilteredCutterProfiles && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Cutter Profiles">
                <Box flexDirection="column">
                  {filteredCutterProfiles.map(cutterProfile => (
                    <CutterProfileListItem
                      key={cutterProfile.id}
                      cutterProfile={cutterProfile}
                    />
                  ))}
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredCutterProfilesCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
              pageFilters={pageFilters}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
