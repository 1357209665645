import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import {
  faBox,
  faBuilding,
  faExchange,
  faFilterCircleDollar,
  faGlobe,
  faHandHoldingDollar,
  faLayerGroup,
  faList,
  faPaintBrush,
  faPaintRoller,
  faScissors,
  faSync,
  faTelescope,
  faUpload,
  faUser,
} from '@fortawesome/pro-light-svg-icons'

import { FACEBOOK_AD_TYPE, GOOGLE_DISPLAY_AD_TYPE, GOOGLE_SEARCH_AD_TYPE } from '@functions/adType'

const generateMainNavigationItems = () => ({
  sections: [
    {
      key: 'dashboard',
      title: 'Marketing',
      visible: true,
      items: [
        {
          href: '#/?orderStatus=Failed',
          icon: faTelescope,
          key: 'monitoring',
          title: 'Monitoring',
          visible: true,
        },
        {
          href: '#/orders',
          icon: faBox,
          key: 'orders',
          title: 'Orders',
          visible: true,
        },
        {
          href: '#/validator',
          icon: faSync,
          key: 'validator',
          title: 'Validator',
          visible: true,
        },
        {
          icon: faUpload,
          key: 'adDeployer',
          title: 'Ad Deployer',
          visible: true,
          items: [
            {
              href: '#/adDeployer',
              icon: faGlobe,
              key: 'all',
              title: 'All Channels',
              visible: true,
            },
            {
              href: `#/adDeployer?adType=${FACEBOOK_AD_TYPE}`,
              icon: faFacebook,
              key: 'facebook',
              title: 'Facebook',
              visible: true,
            },
            {
              href: `#/adDeployer?adType=${GOOGLE_DISPLAY_AD_TYPE}`,
              icon: faGoogle,
              key: 'googleDisplay',
              title: 'Google Display',
              visible: true,
            },
            {
              href: `#/adDeployer?adType=${GOOGLE_SEARCH_AD_TYPE}`,
              icon: faGoogle,
              key: 'googleSearch',
              title: 'Google Search',
              visible: true,
            },
          ],
        },
        {
          icon: faExchange,
          key: 'adLoader',
          title: 'Ad Loader',
          visible: true,
          items: [
            {
              href: '#/adLoader',
              icon: faGlobe,
              key: 'all',
              title: 'All Channels',
              visible: true,
            },
            {
              href: `#/adLoader?adType=${FACEBOOK_AD_TYPE}`,
              icon: faFacebook,
              key: 'facebook',
              title: 'Facebook',
              visible: true,
            },
            {
              href: `#/adLoader?adType=${GOOGLE_DISPLAY_AD_TYPE}`,
              icon: faGoogle,
              key: 'googleDisplay',
              title: 'Google Display',
              visible: true,
            },
            {
              href: `#/adLoader?adType=${GOOGLE_SEARCH_AD_TYPE}`,
              icon: faGoogle,
              key: 'googleSearch',
              title: 'Google Search',
              visible: true,
            },
          ],
        },
        {
          href: '#/autoOrderQueues',
          icon: faList,
          key: 'autoOrderQueues',
          title: 'Auto Order Queues',
          visible: true,
        },
        {
          href: '#/brands',
          icon: faBuilding,
          key: 'brands',
          title: 'Brands',
          visible: true,
        },
        {
          href: '#/organisations',
          icon: faGlobe,
          key: 'organisations',
          title: 'Organisations',
          visible: true,
        },
        {
          href: '#/products',
          icon: faLayerGroup,
          key: 'products',
          title: 'Products',
          visible: true,
        },
        {
          href: '#/productOptions',
          icon: faFilterCircleDollar,
          key: 'productOptions',
          title: 'Product Options',
          visible: true,
        },
        {
          href: '#/priceLists',
          icon: faHandHoldingDollar,
          key: 'priceLists',
          title: 'Price Lists',
          visible: true,
        },
        {
          href: '#/users',
          icon: faUser,
          key: 'users',
          title: 'Users',
          visible: true,
        },
        {
          icon: faPaintBrush,
          key: 'templates',
          title: 'Artworks',
          visible: true,
          items: [
            {
              href: '#/artworkTemplateGroups',
              icon: faPaintRoller,
              key: 'artworkTemplateGroups',
              title: 'Template Groups',
              visible: true,
            },
            {
              href: '#/cutterProfiles',
              icon: faScissors,
              key: 'cutterProfiles',
              title: 'Cutter Profiles',
              visible: true,
            },
          ],
        },
      ],
    },
  ],
})

export default generateMainNavigationItems
