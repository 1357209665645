import cloneDeep from 'lodash/cloneDeep'
import deepmerge from 'deepmerge'

import * as ModelTypes from '@models/types'

import type { ModuleState } from '@redux/modules/types'

const DELETE_ENTITY = 'realbase/entity/DELETE_ENTITY'
const REPLACE_ENTITY = 'realbase/entity/REPLACE_ENTITY'
const UPDATE_ENTITY = 'realbase/entity/UPDATE_ENTITY'

// Add Entities & their Model Type Reference here
export type EntitiesState = {
  // START ENTITY TYPES
  _updates: {},
  activityLogs: { [key: number]: ModelTypes.ActivityLogModel },
  adAccountLinks: { [key: number]: ModelTypes.AdAccountLinkModel },
  adAccounts: { [key: number]: ModelTypes.AdAccountModel },
  adCampaignAds: { [key: number]: ModelTypes.AdCampaignAdModel },
  adCampaigns: { [key: number]: ModelTypes.AdCampaignModel },
  adQueueStatuses: { [key: number]: ModelTypes.AdQueueStatusModel },
  adQueues: { [key: number]: ModelTypes.AdQueueModel },
  adTemplatePayloads: { [key: number]: ModelTypes.AdTemplatePayloadModel },
  adTemplateProducts: { [key: number]: ModelTypes.AdTemplateProductModel },
  adTemplates: { [key: number]: ModelTypes.AdTemplateModel },
  artworkTemplateGroupLinks: { [key: number]: ModelTypes.ArtworkTemplateGroupLinkModel },
  artworkTemplateGroups: { [key: number]: ModelTypes.ArtworkTemplateGroupModel },
  artworkTemplates: { [key: number]: ModelTypes.ArtworkTemplateModel },
  artworks: { [key: number]: ModelTypes.ArtworkModel },
  assetTypes: { [key: number]: ModelTypes.AssetTypeModel },
  assets: { [key: number]: ModelTypes.AssetModel },
  autoOrderQueues: { [key: number]: ModelTypes.AutoOrderQueueModel },
  autoOrderQueueStatuses: { [key: number]: ModelTypes.AutoOrderQueueStatusModel },
  brands: { [key: number]: ModelTypes.BrandModel },
  campaignStatuses: { [key: number]: ModelTypes.CampaignStatusModel },
  campaignTypes: { [key: number]: ModelTypes.CampaignTypeModel },
  campaignUsers: { [key: number]: ModelTypes.CampaignUserModel },
  campaignValidations: { [key: number]: ModelTypes.CampaignValidationModel },
  campaigns: { [key: number]: ModelTypes.CampaignModel },
  captions: { [key: number]: ModelTypes.CaptionModel },
  clientPriceLists: { [key: number]: ModelTypes.ClientPriceListModel },
  clientUsers: { [key: number]: ModelTypes.ClientUserModel },
  clients: { [key: number]: ModelTypes.ClientModel },
  comments: { [key: number]: ModelTypes.CommentModel },
  conversations: { [key: number]: ModelTypes.ConversationModel },
  cutterProfileModules: { [key: number]: ModelTypes.CutterProfileModuleModel },
  cutterProfiles: { [key: number]: ModelTypes.CutterProfileModel },
  dataStoreItems: { [key: number]: ModelTypes.DataStoreItemModel },
  externalPlatformEntities: { [key: number]: ModelTypes.ExternalPlatformEntityModel },
  externalPlatforms: { [key: number]: ModelTypes.ExternalPlatformModel },
  facebookCampaigns: { [key: number]: ModelTypes.FacebookCampaignModel },
  googleCampaigns: { [key: number]: ModelTypes.GoogleCampaignModel },
  landingPages: { [key: number]: ModelTypes.LandingPageModel },
  orderItemOptionValues: { [key: number]: ModelTypes.OrderItemOptionValueModel },
  orderItems: { [key: number]: ModelTypes.OrderItemModel },
  orders: { [key: number]: ModelTypes.OrderModel },
  packageProducts: { [key: number]: ModelTypes.PackageProductModel },
  pendingGoogleCampaigns: { [key: number]: ModelTypes.PendingGoogleCampaignModel },
  priceListItemOptionValues: { [key: number]: ModelTypes.PriceListItemOptionValueModel },
  priceListItemOptions: { [key: number]: ModelTypes.PriceListItemOptionModel },
  priceListItems: { [key: number]: ModelTypes.PriceListItemModel },
  priceLists: { [key: number]: ModelTypes.PriceListModel },
  productOptionKeyTypes: { [key: number]: ModelTypes.ProductOptionKeyTypeModel },
  productOptionProducts: { [key: number]: ModelTypes.ProductOptionProductModel },
  productOptionValues: { [key: number]: ModelTypes.ProductOptionValueModel },
  productOptionValuesSubsets: { [key: number]: ModelTypes.ProductOptionValuesSubsetModel },
  productOptions: { [key: number]: ModelTypes.ProductOptionModel },
  productPackages: { [key: number]: ModelTypes.ProductPackageModel },
  products: { [key: number]: ModelTypes.ProductModel },
  projects: { [key: number]: ModelTypes.ProjectModel },
  properties: { [key: number]: ModelTypes.PropertyModel },
  realhubArtworkTemplates: { [key: number]: ModelTypes.RealhubArtworkTemplateModel },
  userTypes: { [key: number]: ModelTypes.UserTypeModel },
  users: { [key: number]: ModelTypes.UserModel },
  // END ENTITY TYPES
}

const initialState: EntitiesState = {
  // START ENTITIES STATE
  _updates: {},
  activityLogs: {},
  adAccountLinks: {},
  adAccounts: {},
  adCampaignAds: {},
  adCampaigns: {},
  adQueueStatuses: {},
  adQueues: {},
  adTemplatePayloads: {},
  adTemplateProducts: {},
  adTemplates: {},
  artworkTemplateGroupLinks: {},
  artworkTemplateGroups: {},
  artworkTemplates: {},
  artworks: {},
  assetTypes: {},
  assets: {},
  autoOrderQueues: {},
  autoOrderQueueStatuses: {},
  brands: {},
  campaignStatuses: {},
  campaignTypes: {},
  campaignUsers: {},
  campaignValidations: {},
  campaigns: {},
  captions: {},
  clientPriceLists: {},
  clientUsers: {},
  clients: {},
  comments: {},
  conversations: {},
  cutterProfileModules: {},
  cutterProfiles: {},
  dataStoreItems: {},
  externalPlatformEntities: {},
  externalPlatforms: {},
  facebookCampaigns: {},
  googleCampaigns: {},
  landingPages: {},
  orderItemOptionValues: {},
  orderItems: {},
  orders: {},
  packageProducts: {},
  pendingGoogleCampaigns: {},
  priceListItemOptionValues: {},
  priceListItemOptions: {},
  priceListItems: {},
  priceLists: {},
  productOptionKeyTypes: {},
  productOptionProducts: {},
  productOptionValues: {},
  productOptionValuesSubsets: {},
  productOptions: {},
  productPackages: {},
  products: {},
  projects: {},
  properties: {},
  realhubArtworkTemplates: {},
  userTypes: {},
  users: {},
  // END ENTITIES STATE
}

// Normalized Data
type EntitiesActionFunctionPayload = {
  entities: EntitiesState,
}

type ReducerResult = EntitiesActionFunctionPayload['entities'] & {
  type: string,
}

type Action = Partial<EntitiesState> & Partial<ModuleState> & {
  type?: string,
}

export function updateEntities(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: UPDATE_ENTITY, ...payload.entities }
}

export function replaceEntity(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: REPLACE_ENTITY, ...payload.entities }
}

export function deleteEntity(payload: EntitiesActionFunctionPayload): ReducerResult {
  return { type: DELETE_ENTITY, ...payload.entities }
}

function cleanKeys(
  keys: (keyof Action)[] = [],
  removeKeys: (keyof Action)[] = [],
): (keyof EntitiesState)[] {
  removeKeys.forEach((removeKey) => {
    const index = keys.indexOf(removeKey)
    keys.splice(index, 1)
  })

  return keys
}

function removeStaleEntities(currentEntityState, actionEntityState) {
  const currentKeys = Object.keys(currentEntityState)
  const actionKeys = Object.keys(actionEntityState)

  const updatedActionEntityState = cloneDeep(actionEntityState)

  actionKeys.forEach((actionKey) => {
    // If we already have the key, we need to check if the next one
    // is newer using cache key
    if (currentKeys.includes(actionKey)) {
      const actionEntity = updatedActionEntityState[actionKey]
      const currentEntity = currentEntityState[actionKey]

      // Remove the entity from actionEntityState if it is older
      // than the one we already have in state
      if (
        typeof actionEntity === 'object'
        && actionEntity !== null
        && actionEntity.cacheKey
        && currentEntity.cacheKey
        && actionEntity.cacheKey < currentEntity.cacheKey
      ) {
        delete updatedActionEntityState[actionKey]
      }
    }
  })

  return updatedActionEntityState
}

// Reducers
function addEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  // Don't merge arrays
  const dontMerge = (_, source) => source
  const mergeOptions = { arrayMerge: dontMerge }

  keys.forEach((key) => {
    const updatedActionEntityState = removeStaleEntities(newState[key], action[key])

    newState[key] = deepmerge(newState[key], updatedActionEntityState, mergeOptions)
  })

  return newState
}

function replaceEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]) {
      newState[key] = { ...state[key] }

      // Keys of the item we need to replace
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        newState[key][itemKey] = action[key][itemKey]
      })
    }
  })

  return newState
}

function removeEntities(state: EntitiesState, action: Action): EntitiesState {
  const keys: (keyof EntitiesState)[] = cleanKeys(Object.keys(action), ['type'])
  const newState = { ...state }

  keys.forEach((key) => {
    if (newState[key]) {
      newState[key] = { ...state[key] }

      // Keys of the item we need to remove
      const itemKeys = Object.keys(action[key])
      itemKeys.forEach((itemKey) => {
        delete newState[key][itemKey]
      })
    }
  })

  return newState
}

export default function reducer(state: EntitiesState = initialState, action: Action = {}) {
  switch (action.type) {
    case UPDATE_ENTITY:
      return addEntities(state, action)
    case REPLACE_ENTITY:
      return replaceEntities(state, action)
    case DELETE_ENTITY:
      return removeEntities(state, action)
    default:
      return state
  }
}
