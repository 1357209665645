import { useForm, useLatestEntity } from '@campaignhub/react-hooks'
import type { UseFormOptions } from '@campaignhub/react-hooks'

import useDispatch from '@hooks/useDispatch'
import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import * as clientActions from '@redux/modules/client'

import defaultFormState, { requiredFields } from '@models/client'

import type { AppDispatch } from '@redux/store'
import type { DeleteParams, ModuleState } from '@redux/modules/types'
import type { ClientModel, ClientRequestOptions } from '@models/types'

export const generateUrls = (client?: Partial<ClientModel>) => {
  const { id } = client || {}

  return {
    archivedClientsIndexUrl: '#/organisations?isArchived=true',
    clientsIndexUrl: '#/organisations',
    editClientRelationsUrl: `#/organisations/${id}/edit/relations/`,
    editClientUrl: `#/organisations/${id}/edit/`,
  }
}

type CreateClientParams = {
  clientParams: Partial<ClientModel>,
  dispatch: AppDispatch,
  requestOptions?: ClientRequestOptions,
}

const createClient = (params: CreateClientParams) => {
  const { dispatch, clientParams, requestOptions } = params
  const { createClient: createFn } = clientActions

  return dispatch(createFn(clientParams, requestOptions))
}

type UpdateClientParams = {
  client: ClientModel,
  clientParams: Partial<ClientModel>,
  dispatch: AppDispatch,
  requestOptions?: ClientRequestOptions,
}

const updateClient = (params: UpdateClientParams) => {
  const {
    dispatch, client, clientParams, requestOptions,
  } = params
  const { updateClient: updateFn } = clientActions

  const updatedParams = {
    id: client.id,
    ...clientParams,
  }

  return dispatch(updateFn(updatedParams, requestOptions))
}

type DeleteClientParams = {
  client: DeleteParams<ClientModel>,
  dispatch: AppDispatch,
}

const deleteClient = (params: DeleteClientParams) => {
  const { dispatch, client } = params
  const { deleteClient: deleteFn } = clientActions

  return dispatch(deleteFn(client))
}

type PatchClientModel = Pick<ClientModel, 'additionalNotificationEmail'>

type PatchClientParams = {
  dispatch: AppDispatch,
  client: ClientModel,
  clientParams: PatchClientModel,
  requestOptions?: ClientRequestOptions,
}

const patchClient = (params: PatchClientParams) => {
  const {
    dispatch, client, clientParams, requestOptions,
  } = params
  const { patchClient: patchFn } = clientActions

  const updatedParams = {
    id: client.id,
    ...clientParams,
  }

  return dispatch(patchFn(updatedParams, requestOptions))
}

type CustomFormOptions = {
  customRequiredFields?: UseFormOptions['requiredFields'],
}

export function useClientForm(
  client: Partial<ClientModel>,
  options: UseFormOptions & CustomFormOptions = {},
) {
  const { customRequiredFields = [], validateOn } = options || {}

  const clientForm = useForm(
    defaultFormState,
    { entity: client, requiredFields: [...requiredFields, ...customRequiredFields], validateOn },
    [client.id, client.cacheKey],
  )

  return {
    ...clientForm,
  }
}

export const useRelations = (client: Partial<ClientModel> = {}) => {
  const { brandId } = client

  const { brands } = useSelector(reduxState => reduxState.entities)

  const brand = brandId && brands[brandId] ? brands[brandId] : {}

  return {
    brand,
  }
}

type Options = {
  performHttpRequests?: boolean,
  requestOptions?: ClientRequestOptions,
}

export function useClient(initEntity: Partial<ClientModel> = {}, options: Options = {}) {
  const { performHttpRequests, requestOptions } = options

  const { entity: client }: { entity: ClientModel } = useLatestEntity(initEntity, 'clients')
  const { id } = client

  const dispatch = useDispatch()

  const { brand } = useRelations(client)

  useReduxAction(
    'clients',
    'loadClient',
    {
      entityId: id,
      ...requestOptions,
    },
    [id, performHttpRequests],
    {
      dispatchAction: (action, actionRequestOptions) => action(id, actionRequestOptions),
      shouldPerformFn: ({ loading }: ModuleState) => !!performHttpRequests && !loading,
    },
  )

  const {
    creating, deleting, loading, updating,
  } = useSelector(reduxState => reduxState.clients)

  return {
    brand,
    callbacks: {
      createClient: (clientParams: Partial<ClientModel>, entityOptions?: ClientRequestOptions) => (
        createClient({ clientParams, dispatch, requestOptions: entityOptions })
      ),
      deleteClient: () => deleteClient({ dispatch, client }),
      updateClient: (clientParams: Partial<ClientModel>, entityOptions?: ClientRequestOptions) => (
        updateClient({
          client, clientParams, dispatch, requestOptions: entityOptions,
        })
      ),
      patchClient: (
        clientParams: PatchClientModel,
        entityOptions?: ClientRequestOptions
      ) => (
        patchClient({
          client, clientParams, dispatch, requestOptions: entityOptions,
        })
      ),
    },
    client,
    creating,
    deleting,
    loading,
    updating,
    urls: generateUrls(client),
  }
}

export default useClient
