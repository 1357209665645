export type ProjectModel = {
  agentName?: string,
  cacheKey: number,
  clientId?: number,
  clientName?: string,
  customFieldSetId: number,
  id: number,
  landingPageId: number,
  name: string,
  referenceId?: number,
  userId?: number,
}

export type ProjectRequestOptions = {}

const state = {
  agentName: '',
  clientId: null,
  clientName: '',
  customFieldSetId: null,
  dataStoreItems: [],
  id: null,
  landingPageId: null,
  name: '',
  referenceId: null,
  userId: null,
}

export const requiredFields = [
  { key: 'dataStoreItems.PostCode' },
  { key: 'dataStoreItems.State' },
  { key: 'dataStoreItems.Street' },
  { key: 'dataStoreItems.StreetNumber' },
  { key: 'dataStoreItems.Suburb' },
]

export default state