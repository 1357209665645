import { useMemo } from 'react'

import { camelCaseToTitleCase, formatDate } from '@campaignhub/javascript-utils'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import {
  StatusBadge, Table, Text,
} from '@campaignhub/suit-theme'

import { getAdQueueStartDate } from '@functions/adQueue'
import getStatusBadgeProps from '@functions/statusBadge'

import useSelector from '@hooks/useSelector'

import { AdQueueModel } from '@models/types'

import type { EntitiesState } from '@redux/entities'

import Actions from './Actions'

const buildColumns = (entities: EntitiesState) => {
  const {
    campaigns,
    externalPlatformEntities,
    externalPlatforms,
    projects,
  } = entities

  return [
    {
      title: 'Campaign',
      dataKey: 'campaignName',
      cellProps: {
        verticalAlign: 'middle',
      },
      render: (_: any, adQueue: AdQueueModel) => {
        const { campaignId } = adQueue
        const campaign = campaigns[campaignId]
        const { name } = campaign || {}

        if (name) return name

        const { adPayload } = adQueue
        const { 
          name : lowerCaseName,
          Name : upperCaseName, 
        } = JSON.parse(adPayload)

        return upperCaseName ?? lowerCaseName
      },
    },
    {
      title: 'AIM#',
      dataKey: 'campaignId',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_: any, adQueue: AdQueueModel) => {
        const { 
          campaignId,
          referenceId
        } = adQueue

        return (
          <Text variant="ellipsis">
            {campaignId ?? referenceId}
          </Text>
        )
      },
    },
    {
      title: 'Deployment Stack',
      dataKey: 'deploymentStack',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
      },
      render: (_: any, adQueueModel: AdQueueModel) => (
        <Text variant="ellipsis">
          {adQueueModel.campaignId ? 'V2' : 'V1'}
        </Text>
      ),
    },
    {
      title: 'Due',
      dataKey: 'campaignRequiredAt',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_: any, adQueue: AdQueueModel) => {
        const startDate = getAdQueueStartDate(adQueue)

        if (startDate) {
          return (
            <StatusBadge
              boxProps={{
                backgroundColor: 'backgroundColor',
                borderColor: 'lineColor',
                color: 'black',
              }}
              text={formatDate(startDate, 'ISO8601', 'dd/MM/yyyy')}
            />
          )
        }
      },
    },
    {
      title: 'Source (Id)',
      dataKey: 'source',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_:any, adQueue: AdQueueModel) => {
        const { campaignId } = adQueue
        const campaign = campaigns[campaignId]
        const { projectId } = campaign || {}
        const project = projects[projectId]

        const { externalPlatformEntities: projectExternalPlatformEntities } = project || {}
        const sourceStrings = [] as string[]

        const externalPlatformEntityIds: number[] = projectExternalPlatformEntities || []
        externalPlatformEntityIds.forEach(externalPlatformEntityId => {
          const externalPlatformEntity = externalPlatformEntities[externalPlatformEntityId]
          const { externalPlatformId, value } = externalPlatformEntity
          const externalPlatform = externalPlatforms[externalPlatformId]
          const { name } = externalPlatform

          sourceStrings.push(`${name} (${value})`)
        })

        return sourceStrings.join(', ')
      },
    },
    {
      title: 'Channel',
      dataKey: 'channel',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_: any, adQueue: AdQueueModel) => {
        const { adTypeId } = adQueue

        return (
          <Text variant="ellipsis">
            {camelCaseToTitleCase(adTypeId)}
          </Text>
        )
      },
    },
    {
      title: 'Status',
      dataKey: 'status',
      cellProps: {
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 40,
      },
      render: (_: any, adQueue: AdQueueModel) => {
        const { status } = adQueue

        const statusBadgeProps = getStatusBadgeProps(status)

        return (
          <StatusBadge
            boxProps={{ borderWidth: 2 }}
            ghost
            {...statusBadgeProps}
          />
        )
      },
    },
    {
      title: 'Actions',
      dataKey: 'id',
      cellProps: {
        key: 'actions',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: 20,
      },
      render: (adQueueId: number) => <Actions adQueueId={adQueueId} />,
    },
  ]
}

const watchEntityKeys = ['adQueues', 'campaigns']

interface DeployingCampaignsTableProps {
  adQueues: AdQueueModel[],
}

const DeployingCampaignsTable = (props: DeployingCampaignsTableProps) => {
  const { adQueues } = props

  const {
    updatedEntities: {
      adQueues: adQueuesUpdatedAt,
      campaigns: campaignsUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)

  const memoColumns = useMemo(() => (
    buildColumns(entities)
  ), [adQueuesUpdatedAt, campaignsUpdatedAt])

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 1600, overflowY: 'auto' }}
      columns={memoColumns}
      data={adQueues}
      scroll={{ x: 800 }}
      stickyHeader
    />
  )
}

export default DeployingCampaignsTable
