import { useMemo } from 'react'

import {
  formatDate, matchFilterBetweenDates, matchFilterNumber, matchFilterString, sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import getAdCampaignChannel from '@functions/adCampaign'
import { activeAdCampaignStatuses, loadedAdCampaignStatuses } from '@functions/adCampaignStatus'
import { FACEBOOK_AD_TYPE, GOOGLE_DISPLAY_AD_TYPE, GOOGLE_SEARCH_AD_TYPE } from '@functions/adType'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { AdCampaignModel, AdCampaignRequestOptions } from '@models/types'
import type { ModuleState } from '@redux/modules/types'

const getMatchAdType = (
  adCampaign: AdCampaignModel,
  filterAdType?: string,
) => {
  if (!filterAdType) return true

  const adCampaignChannel = getAdCampaignChannel(adCampaign)

  if (adCampaignChannel === 'Facebook') return filterAdType === FACEBOOK_AD_TYPE
  if (adCampaignChannel === 'Google Display') return filterAdType === GOOGLE_DISPLAY_AD_TYPE
  if (adCampaignChannel === 'Google Search') return filterAdType === GOOGLE_SEARCH_AD_TYPE
  return false
}

const getMatchStatus = (
  adCampaign: AdCampaignModel,
  filterStatus?: string,
  showActiveOnly?: boolean,
  showLoadedOnly?: boolean,
) => {
  const { status } = adCampaign

  if (showActiveOnly){
    if (filterStatus){
      return activeAdCampaignStatuses.includes(status) && status === filterStatus
    }
    return activeAdCampaignStatuses.includes(status)
  }

  if (showLoadedOnly){
    if (filterStatus){
      return loadedAdCampaignStatuses.includes(status) && status === filterStatus
    }
    return loadedAdCampaignStatuses.includes(status)
  }

  if (filterStatus) return status === filterStatus

  return true
}

export type AdCampaignFilters = {
  adType?: string,
  campaignId?: number,
  clientId?: number,
  deploymentStack?: string,
  endDate?: string,
  objective?: string,
  sortBy?: 'desc',
  startDate?: string,
  status?: string,
}

type UseAdCampaignsOptions = {
  filters?: AdCampaignFilters,
  performHttpRequests?: boolean,
  requestOptions?: AdCampaignRequestOptions,
  showActiveOnly?: boolean,
  showLoadedOnly?: boolean,
}

const watchEntityKeys = ['adCampaigns']

function useAdCampaigns(options: UseAdCampaignsOptions) {
  const {
    filters = {}, requestOptions, showActiveOnly, showLoadedOnly,
  } = options

  const {
    adType: filterAdType,
    campaignId: filterCampaignId,
    clientId: filterClientId,
    deploymentStack: filterDeploymentStack,
    endDate: filterEndDate,
    objective: filterObjective,
    sortBy: filterSortBy,
    startDate: filterStartDate,
    status: filterStatus,
  } = filters

  const {
    updatedEntities: { adCampaigns: adCampaignsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const { adCampaigns, campaigns } = useSelector(reduxState => reduxState.entities)

  const filteredAdCampaigns = useMemo(() => {
    const filtered = Object.values(adCampaigns).filter((adCampaign) => {
      const {
        campaignId, objective, startsAt, referenceId
      } = adCampaign

      const campaign = campaigns[campaignId] || {}
      const { clientId } = campaign

      const matchAdType = getMatchAdType(adCampaign, filterAdType)

      const matchableId = campaignId ?? referenceId
      const matchCampaignId = matchFilterNumber(matchableId, filterCampaignId)
      
      const matchDeploymentStack = filterDeploymentStack
        ? filterDeploymentStack === 'v1'
          ? campaignId === null
          : campaignId !== null
        : true

      const matchStatus = getMatchStatus(adCampaign, filterStatus, showActiveOnly, showLoadedOnly)

      const matchObjective = matchFilterString(objective, filterObjective)

      const matchClientId = matchFilterNumber(clientId, filterClientId)

      const formattedStartDate = formatDate(new Date(startsAt).toISOString(), 'ISO8601', 'yyyy-MM-dd')
      const matchDates = matchFilterBetweenDates(formattedStartDate, filterStartDate, filterEndDate)

      return matchAdType
        && matchCampaignId
        && matchClientId
        && matchDates
        && matchDeploymentStack
        && matchObjective
        && matchStatus
    })

    return sortArrayBy(filtered, filterSortBy ?? 'desc', 'startsAt')
  }, [adCampaignsUpdatedAt, JSON.stringify(filters)])

  const filteredAdCampaignsCount = filteredAdCampaigns.length
  const hasFilteredAdCampaigns = !!filteredAdCampaignsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredAdCampaignsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingAdCampaigns } = useReduxAction(
    'adCampaigns',
    'loadAdCampaigns',
    {
      ...requestOptions,
      ...filtersWithOffset,
      limit,
      orderBy: "createdAt desc",
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: ({ loading }: ModuleState) => performHttpRequests && !loading,
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAdCampaigns,
    filteredAdCampaignsCount,
    hasFilteredAdCampaigns,
    loading: loadingAdCampaigns,
  }
}

export default useAdCampaigns
