import React from 'react'
import { createRoot } from 'react-dom/client'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import { ToastContainer } from 'react-toastify'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import 'react-toastify/dist/ReactToastify.css'
import '@styles/toast.scss'

import './index.css'
import App from './App'

import * as serviceWorker from './serviceWorker'

const urlParams = new URLSearchParams(window.location.search)

type ToastParams = {
  closeToast?: () => void,
}

const CloseButton = (toastParams: ToastParams) => {
  const { closeToast } = toastParams

  return (
    <a onClick={closeToast}>
      <FontAwesomeIcon icon={faTimes} />
    </a>
  )
}

type CustomAppState = AppState & {
  redirectPath?: string,
}

const handleRedirect = (appState: CustomAppState) => {
  const { redirectPath } = appState || {}

  const { pathname } = window.location

  const newPathname = redirectPath ? `${pathname}${redirectPath}` : pathname

  window.history.replaceState(
    {},
    document.title,
    newPathname,
  )

  // Have to change path again to trigger refresh...
  // Hopefully this is fixed when react-router is updated.
  window.location.pathname = '/'
}

const domNode = document.getElementById('root')
const root = createRoot(domNode!)

root.render(
  <React.StrictMode>
    <Auth0Provider
      application={process.env.REACT_APP_APPLICATION_NAME}
      audience={process.env.REACT_APP_AUTH0_API_AUDIENCE}
      cacheLocation="localstorage"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      onRedirectCallback={(appState: AppState) => handleRedirect(appState)}
      otpToken={urlParams.get('otpToken')}
      redirectUri={window.location.origin}
      useRefreshTokens
      username={urlParams.get('username')}
      advancedOptions={{
        defaultScope: process.env.REACT_APP_AUTH0_SCOPE,
      }}
    >
      <App />

      <ToastContainer
        autoClose={5000}
        closeButton={<CloseButton />}
        hideProgressBar
        icon={false}
      />
    </Auth0Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
