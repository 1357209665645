import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'

import { MainContent, ModalContext, TopBar } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useMainNavigation from '@hooks/useMainNavigation'

import ManageAdDeploymentModal from '@modals/ManageAdDeploymentModal'
import SelectedLocationsModal from '@modals/SelectedLocationsModal'
import SelectedPlaybooksModal from '@modals/SelectedPlaybooksModal'

import { AdQueueModel } from '@models/types'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import PageContent from './components/PageContent'

const defaultState = {
  showManageAdDeploymentModal: false,
  showSelectedLocationsModal: false,
  showSelectedPlaybooksModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

// eslint-disable-next-line
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    ManageAdDeploymentModal: {
      closeModal: () => setState({ showManageAdDeploymentModal: false }),
      deleteAdQueue: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      processAdQueue: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
    SelectedLocationsModal: {
      closeModal: () => setState({ showSelectedLocationsModal: false }),
    },
    SelectedPlaybooksModal: {
      closeModal: () => setState({ showSelectedPlaybooksModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const AdDeployer = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const [state, setState] = useSetState(defaultState)
  const {
    showManageAdDeploymentModal,
    showSelectedLocationsModal,
    showSelectedPlaybooksModal,
  } = state

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  // adType filter in url does not work without this
  useLocation()

  const { adType }: { adType?: string } = parsePermittedQueryParams(getQueryParamsFromHash(), ['adType'])

  const filtersPayload = useFilters({
    defaultFilters: {
      adType,
      deploymentStack: 'v2',
    },
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      showManageAdDeploymentModal: (payload: { adQueue: AdQueueModel }) => {
        setModalData('ManageAdDeploymentModal', payload)
        setState({ showManageAdDeploymentModal: true })
      },
      showSelectedLocationsModal: (payload: { adQueue: AdQueueModel }) => {
        setModalData('SelectedLocationsModal', payload)
        setState({ showSelectedLocationsModal: true })
      },
      showSelectedPlaybooksModal: (payload: { adQueue: AdQueueModel }) => {
        setModalData('SelectedPlaybooksModal', payload)
        setState({ showSelectedPlaybooksModal: true })
      },
      ...filterCallbacks,
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt, modalContext])

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <PageContext.Provider value={pageContext}>
          <ModalContext.Provider value={modalContext}>
            <PageContent pageFilters={pageFilters} />

            <ManageAdDeploymentModal
              callbacks={callbacks('ManageAdDeploymentModal', setState)}
              showModal={showManageAdDeploymentModal}
            />

            <SelectedLocationsModal
              callbacks={callbacks('SelectedLocationsModal', setState)}
              showModal={showSelectedLocationsModal}
            />

            <SelectedPlaybooksModal
              callbacks={callbacks('SelectedPlaybooksModal', setState)}
              showModal={showSelectedPlaybooksModal}
            />
          </ModalContext.Provider>
        </PageContext.Provider>
      </MainContent>
    </>
  )
}

export default AdDeployer
