import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useFilters, useModals } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {},
}

const List = () => {
  useLocation()

  const { isArchived }: { isArchived?: string } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])

  const filtersPayload = useFilters({
    defaultFilters: { isArchived },
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modals

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List
