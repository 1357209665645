export type AudiencesModel = {
  name: string,
  type: string,
}

export type DemographicsModel = {
  ageRanges: string[],
  genders: string[],
}

export type LocationsModel = {
  country?: string,
  latitude?: number,
  longitude?: string,
  name: string,
  radius?: number,
  type: string,
}

export type AdGroupModel = {
  audiences: AudiencesModel[],
  demographics: DemographicsModel,
  id: number,
  locations?: LocationsModel[],
  name: string,
}

type GoogleAdModel = {
  id: number,
  googleAdGroupEntityId: number,
  googleAdId: string,
  googleAdName: string,
  googleType: string,
}

type GoogleAdGroupModel = {
  id: number,
  googleAdGroupId: string,
  googleAdGroupName: string,
  googleAds: GoogleAdModel[],
  googleCampaignEntityId: number,
  googleStatus: string,
  googleType: string,
}

export type AdCampaignModel = {
  adCampaignId: number,
  adCampaignName: string,
  adChannelTypeId: string,
  adCount?: number,
  adGroupCount?: number,
  adGroups: AdGroupModel[],
  adQueueId?: number,
  cacheKey: number,
  campaignId: number,
  createdBy: string,
  demographics: {
    ageMax: number,
    ageMin: number,
    gender: string,
  },
  endsAt: string,
  errorMessage?: string,
  facebookAdCampaign?: {
    facebookAdCreativeId: string,
    facebookAdId: string,
    facebookAdSetId: string,
    facebookLeadFormId: string,
    facebookPageId: string,
  },
  googleAdCampaign?: {
    googleAdGroups: GoogleAdGroupModel[],
    googleCampaignTypeId: string,
  },
  id: number,
  locations: { location: string, radius: string }[],
  objective: string,
  reporting?: {
    costPerClick: number,
    clickThroughRate: number,
    impressions: number,
    interactions: number,
  },
  referenceId: number | null,
  spend: {
    budget: {
      period: string,
      value: number,
    },
    spent: {
      period: string,
      value: number,
    },
  },
  startsAt: string,
  status: string,
  statusMessage: string,
}

export type AdCampaignRequestOptions = {}

const state = {
  adCampaignId: null,
  adChannelTypeId: '',
  adCount: null,
  adGroupCount: null,
  campaignId: null,
  demographics: {},
  errors: [],
  id: null,
  locations: {},
  name: '',
  objective: '',
  reporting: {},
  selectedPlaybooks: [],
  spend: {
    spent: {
      period: '',
      value: null,
    },
    budget: {
      period: '',
      value: null,
    },
  },
  status: '',
}

export const requiredFields = [
  { key: 'name' },
]

export default state
