import { useMemo } from 'react'

import {
  formatDate,
  matchFilterArrayIncludes,
  matchFilterNumber,
  matchFilterString,
  sortArrayBy,
} from '@campaignhub/javascript-utils'

import { useLoadMore, useWatchEntityUpdates } from '@campaignhub/react-hooks'

import { matchFilterBetweenDates } from '@functions/dates'

import useReduxAction from '@hooks/useReduxAction'
import useSelector from '@hooks/useSelector'

import type { CampaignModel } from '@models/types'

import type { EntityOptions, ModuleState } from '@redux/modules/types'

const getMatchOrderStatus = (orderStatuses: string[], filterOrderStatus?: string) => {
  if (!filterOrderStatus) return true

  // Campaign Validations have status Error, not Failed
  // we want to return campaigns with nested 'Error' validations if the filter is set to 'Failed'
  return orderStatuses.some(status => (
    (status === filterOrderStatus) || (filterOrderStatus === 'Failed' && status === 'Error')
  ))
}

const watchEntityKeys = ['campaigns']

export type CampaignFilters = {
  clientId?: number,
  endDate?: string,
  externalPlatformId?: number,
  orderStatus?: string,
  sortBy?: 'desc',
  startDate?: string,
  status?: string,
  string?: string,
  validationStatus?: string,
}

type useCampaignsOptions = {
  filters?: CampaignFilters,
  hidePassedValidations?: boolean,
  performHttpRequests?: boolean,
  requestOptions?: EntityOptions,
}

function useCampaigns(options: useCampaignsOptions = {}) {
  const {
    filters = {},
    hidePassedValidations,
    requestOptions,
  } = options

  const {
    clientId: filterClientId,
    endDate: filterEndDate,
    externalPlatformId: filterExternalPlatformId,
    orderStatus: filterOrderStatus,
    sortBy: filterSortBy,
    startDate: filterStartDate,
    status: filterStatus,
    string: filterString,
    validationStatus: filterValidationStatus,
  } = filters

  const filterEndsDateWithTimezone = filterEndDate ? formatDate(filterEndDate, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined
  const filterStartDateWithTimezone = filterStartDate ? formatDate(filterStartDate, 'yyyy-MM-dd', 'yyyy-MM-dd\'T\'HH:mm:ssZZ') : undefined

  const {
    updatedEntities: { campaigns: campaignsUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const entities = useSelector(reduxState => reduxState.entities)
  const {
    campaigns, externalPlatformEntities, externalPlatforms, landingPages,
  } = entities || {}

  const filteredCampaigns = useMemo((): CampaignModel[] => {
    const filtered = Object.values(campaigns).filter((campaign: CampaignModel) => {
      const {
        campaignSummary,
        clientId,
        id,
        name,
        projectId,
        requiredAt,
        statusId,
      } = campaign

      const externalPlatformEntity = Object.values(externalPlatformEntities).find(externalPlatformEntity => (
        externalPlatformEntity.entityId === projectId && externalPlatformEntity.entityType === 'Project'
      ))

      const externalPlatform = externalPlatforms[externalPlatformEntity?.externalPlatformId]

      campaign.externalPlatform = externalPlatform?.name || 'No Source'

      campaign.landingPage = landingPages[campaign?.landingPageId]

      const {
        facebook,
        googleDisplay,
        googleSearch,
        preflightValidation,
      } = campaignSummary || {}

      const { status: facebookStatus } = facebook || {}
      const { status: googleDisplayStatus } = googleDisplay || {}
      const { status: googleSearchStatus } = googleSearch || {}
      const {
        campaignStatus: campaignValidationStatus,
        facebookStatus: facebookValidationStatus,
        googleStatus: googleValidationStatus,
        status: preflightStatus,
      } = preflightValidation || {}

      const matchClientId = matchFilterNumber(clientId, filterClientId)

      const matchDates = matchFilterBetweenDates(requiredAt, filterStartDateWithTimezone, filterEndsDateWithTimezone)

      const orderStatuses = [facebookStatus, googleDisplayStatus, googleSearchStatus, preflightStatus].filter(x => x)
      const matchOrderStatus = getMatchOrderStatus(orderStatuses, filterOrderStatus)

      const validationStatuses = [campaignValidationStatus, facebookValidationStatus, googleValidationStatus]

      // The Validator will not show campaigns if all validations are successful
      // so the hidePassedValidations option is used to hide those campaigns on that page
      let matchValidationStatus = true
      if (filterValidationStatus) {
        matchValidationStatus = matchFilterArrayIncludes(validationStatuses, filterValidationStatus)
      } else if (hidePassedValidations) {
        matchValidationStatus = validationStatuses.some(status => status === 'Error' || status === 'Warning')
      }

      const matchStatus = matchFilterString(statusId, filterStatus)

      const matchId = id.toString() === filterString
      const matchName = matchFilterString(name, filterString)
      const matchString = matchId || matchName

      const matchExternalPlatformId = matchFilterNumber(externalPlatform?.id, filterExternalPlatformId)

      return matchClientId
        && matchDates
        && matchExternalPlatformId
        && matchOrderStatus
        && matchStatus
        && matchString
        && matchValidationStatus
    })

    return sortArrayBy(filtered, filterSortBy ?? 'desc', 'requiredAt')
  }, [campaignsUpdatedAt, JSON.stringify(filters)])

  const filteredCampaignsCount = filteredCampaigns.length
  const hasFilteredCampaigns = !!filteredCampaignsCount

  const loadMorePayload = useLoadMore({
    filters,
    loadedCount: filteredCampaignsCount,
    performHttpRequests: options.performHttpRequests,
  })

  const {
    callbacks: { loadMore },
    canLoadMore,
    filtersWithOffset,
    limit,
    performHttpRequests,
  } = loadMorePayload

  const { loading: loadingCampaigns } = useReduxAction(
    'campaigns',
    'loadCampaigns',
    {
      ...filtersWithOffset,
      ...requestOptions,
      endDate: filterEndsDateWithTimezone,
      startDate: filterStartDateWithTimezone,
      limit,
      orderBy: "createdAt desc"
    },
    [filtersWithOffset, performHttpRequests],
    {
      shouldPerformFn: (entityReducer: ModuleState) => {
        const { loading, errors } = entityReducer
        return performHttpRequests && !loading && !errors?.length
      },
    },
  )

  return {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredCampaigns,
    filteredCampaignsCount,
    hasFilteredCampaigns,
    loading: loadingCampaigns,
  }
}

export default useCampaigns
