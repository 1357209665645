import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Button, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import getActiveTabBar from '@functions/tabBar'

import useProducts from '@hooks/useProducts'
import { generateUrls } from '@hooks/useProduct'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import ProductListItem from '../ProductListItem'

import type { PageContextPayload } from '../../index'

const buildPageFilterFields = () => {
  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by product name or ID',
        style,
      },
      key: 'string',
      label: 'Name or ID',
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const {
    isArchived: archived,
  } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])

  const isArchived = archived === 'true'

  const productsPayload = useProducts({
    filters: {
      ...pageFilters,
      isArchived,
    },
    performHttpRequests: true,
    requestOptions: {},
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredProducts,
    filteredProductsCount,
    hasFilteredProducts,
    loading,
  } = productsPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      showCreateProductModal,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const {
    archivedProductsIndexUrl,
    productsIndexUrl,
  } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Products | AIM Digital</title>
      </Helmet>

      <PageHeader
        actionContent={(
          <Button buttonStyle="primaryCreate" onClick={() => showCreateProductModal()} size="medium">
            Create
          </Button>
        )}
        activeTabBarItemKey={getActiveTabBar({ isArchived })}
        tabBarItems={[
          {
            href: productsIndexUrl,
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          },
          {
            href: archivedProductsIndexUrl,
            icon: faBan,
            key: 'archived',
            title: 'Archived',
          },
        ]}
        title="Products"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredProducts && <ListBlankState />}

            {!loading && hasFilteredProducts && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Products">
                <Box flexDirection="column">
                  {filteredProducts.map(product => (
                    <ProductListItem
                      key={product.id}
                      product={product}
                    />
                  ))}
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredProductsCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields()}
              pageFilters={pageFilters}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
