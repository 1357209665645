import { useMemo } from 'react'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import CreateProductOptionModal from '@modals/CreateProductOptionModal'

import type { ProductOptionModel } from '@models/types'

import PageContent from './components/PageContent'

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showCreateProductOptionModal: () => void,
  },
}

const defaultState = {
  showCreateProductOptionModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    CreateProductOptionModal: {
      closeModal: () => setState({ showCreateProductOptionModal: false }),
      createProductOption: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      updateProductOption: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const { showCreateProductOptionModal } = state

  const { isArchived } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])

  const filtersPayload = useFilters({
    defaultFilters: {
      isArchived,
    },
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showCreateProductOptionModal: (payload: { productOption: ProductOptionModel }) => {
        setModalData('CreateProductOptionModal', payload)
        setState({ showCreateProductOptionModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modals

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <CreateProductOptionModal
          callbacks={callbacks('CreateProductOptionModal', setState)}
          showModal={showCreateProductOptionModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List
