import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import {
  Box, Heading, Image, LoadingBubbles, Link, Text,
} from '@campaignhub/suit-theme'

import LogoMarkUrl from './assets/logo-mark.svg'

const renderLoginLink = (error, loginWithRedirect, logout) => {
  if (error){
    return <Link onClick={() => logout()}>Try Another Account</Link>
  }

  return (
    <Link onClick={() => loginWithRedirect({ application: process.env.REACT_APP_APPLICATION_NAME })}>
      Login
    </Link>
  )
}

const Login = () => {
  const authPayload = useAuth0()
  const {
    error, isAuthenticated, isLoading, loginWithRedirect, logout,
  } = authPayload

  useEffect(() => {
    if (!error && !isAuthenticated && !isLoading){
      loginWithRedirect({
        application: process.env.REACT_APP_APPLICATION_NAME,
        appState: {
          redirectPath: window.location.hash,
        },
      })
    }
  }, [error, isAuthenticated, isLoading, loginWithRedirect])

  return (
    <Box
      alignItems="center"
      backgroundColor="whiteGrey"
      height="100vh"
      justifyContent="center"
      padding={['large', 'xxlarge']}
      width="100%"
    >
      <Box alignItems="center" flexDirection="column" justifyContent="center" width={['100%', '400px']}>
        <Image backgroundSize="contain" url={LogoMarkUrl} width={50} height={50} />

        <Heading textProps={{ color: 'bodyFontLightColor', marginTop: 'xlarge' }}>
          {error ? 'Error' : 'Loading Application...'}
        </Heading>

        {!error && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ marginTop: 32 }} />}

        {error && <Text marginTop="xlarge">{error.message}</Text>}

        <Box alignItems="center" fontSize="xsmall" justifyContent="center" marginTop="xxlarge">
          <Text color="bodyFontLightColor" marginRight="small">Not Loading?</Text>
          {renderLoginLink(error, loginWithRedirect, logout)}
        </Box>
      </Box>
    </Box>
  )
}

export default Login
