import { useMemo } from 'react'

import { formatDate } from '@campaignhub/javascript-utils'

import { useFilters } from '@campaignhub/react-hooks'

import { MainContent, TopBar } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import useMainNavigation from '@hooks/useMainNavigation'

import LoggedInUser from '@components/LoggedInUser'
import MainNavigation from '@sections/Admin/components/MainNavigation'

import PageContent from './components/PageContent'

const OrdersList = () => {
  const { callbacks: { toggleNavigation } } = useMainNavigation()

  const currentDate = formatDate(new Date().toISOString(), 'ISO8601', 'yyyy-MM-dd')

  const filtersPayload = useFilters({
    defaultFilters: {
      endDate: currentDate,
      startDate: currentDate,
    },
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt])

  return (
    <>
      <TopBar
        callbacks={{ toggleNavigation }}
        loggedInUserComponent={<LoggedInUser />}
        searchComponent={<TopBar.Search callbacks={{ search: null }} />}
      />

      <MainNavigation />

      <MainContent offset={{ top: TopBar.topBarHeight }}>
        <PageContext.Provider value={pageContext}>
          <PageContent pageFilters={pageFilters} />
        </PageContext.Provider>
      </MainContent>
    </>
  )
}

export default OrdersList
