import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import useAutoOrderQueues, { AutoOrderQueueFilters } from '@hooks/useAutoOrderQueues'
import { generateUrls } from '@hooks/useAutoOrderQueue'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import AutoOrderQueueTable from '../AutoOrderQueuesTable'

import type { PageContextPayload } from '../../index'

import { AutoOrderQueueStatusModel } from '@models/types'

import useAutoOrderQueueStatuses from '@hooks/useAutoOrderQueueStatuses'

type BuildPageFilterFieldParams = {
  autoOrderQueueStatuses: AutoOrderQueueStatusModel[],
}

const buildPageFilterFields = (params: BuildPageFilterFieldParams) => {
  const { autoOrderQueueStatuses } = params

  const style = { marginBottom: 16 }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by Auto Order Queue ID',
        style,
      },
      key: 'string',
      label: 'Search',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Reference ID',
        style,
      },
      key: 'referenceId',
      label: 'Reference ID',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'status',
      label: 'Status',
      values: autoOrderQueueStatuses.map(autoOrderQueueStatus => (
        { key: autoOrderQueueStatus.name, label: autoOrderQueueStatus.displayName }
      )),
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'startDate',
      label: 'Start Date',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        inputType: 'date',
        style,
      },
      key: 'endDate',
      label: 'End Date',
    },
  ]
}

interface PageContentProps {
  pageFilters: AutoOrderQueueFilters,
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { autoOrderQueueStatuses } = useAutoOrderQueueStatuses({ performHttpRequests: true })

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const autoOrderQueuesPayload = useAutoOrderQueues({
    filters: {
      ...pageFilters,
    },
    performHttpRequests: true,
  })

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredAutoOrderQueuesCount,
    hasFilteredAutoOrderQueues,
    loading,
  } = autoOrderQueuesPayload

  const { autoOrderQueuesIndexUrl } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Auto Order Queues | AIM Digital</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey="active"
        tabBarItems={[
          {
            href: autoOrderQueuesIndexUrl,
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          },
        ]}
        title="Auto Order Queues"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredAutoOrderQueues && <ListBlankState />}

            {!loading && hasFilteredAutoOrderQueues && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Auto Order Queues">
                <Box flexDirection="column">
                  <AutoOrderQueueTable
                    autoOrderQueuesPayload={autoOrderQueuesPayload}
                  />
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredAutoOrderQueuesCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields({ autoOrderQueueStatuses })}
              pageFilters={pageFilters}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
