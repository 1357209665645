import { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import getActiveTabBar from '@functions/tabBar'

import { faBan, faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import {
  Box, Columns, DashboardModule, LoadingModule,
} from '@campaignhub/suit-theme'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import PageContext from '@contexts/pageContext'
import type { DefaultContext } from '@contexts/pageContext'

import useUsers from '@hooks/useUsers'
import { generateUrls } from '@hooks/useUser'
import useUserTypes from '@hooks/useUserTypes'

import ListBlankState from '@components/ListBlankState'
import PageFilterModule from '@components/PageFilterModule'
import PageHeader from '@components/PageHeader'

import { UserTypeModel } from '@models/userType'

import UserListItem from '../UserListItem'

import type { PageContextPayload } from '../../index'

const buildPageFilterFields = (userTypes: UserTypeModel[]) => {
  const style = { marginBottom: 16 }
  const boxProps = { marginBottom: 'large' }

  return [
    {
      component: 'DynamicInput',
      componentProps: {
        inputComponent: 'input',
        placeholder: 'Search by user name or ID',
        style,
      },
      key: 'string',
      label: 'Name or ID',
    },
    {
      component: 'EntitySelector',
      componentProps: {
        boxProps,
        entityKey: 'clients',
        entityTitleKey: 'name',
      },
      key: 'clientId',
    },
    {
      component: 'DynamicInput',
      componentProps: {
        defaultSelectLabel: 'All',
        inputComponent: 'select',
        style,
      },
      key: 'userTypeId',
      label: 'User Role',
      values: userTypes.map(userType => ({ key: userType.id, label: userType.name })),
    },
  ]
}

interface PageContentProps {
  pageFilters: {},
}

const PageContent = (props: PageContentProps) => {
  const { pageFilters } = props

  // useLocation to refresh the window.location. If not used location won't reload if URL changes
  useLocation()

  const { isArchived: archived } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])

  const isArchived = archived === 'true'

  const usersPayload = useUsers({
    filters: {
      ...pageFilters,
      isArchived,
    },
    performHttpRequests: true,
    requestOptions: {
      include: 'ClientUsers',
    },
  })

  const { filteredUserTypes } = useUserTypes({})

  const {
    callbacks: {
      loadMore,
    },
    canLoadMore,
    filteredUsers,
    filteredUsersCount,
    hasFilteredUsers,
    loading,
  } = usersPayload

  const pageContext = useContext<DefaultContext<PageContextPayload>>(PageContext)
  const {
    callbacks: {
      resetFilters,
      updateFilters,
    },
    defaultFilters,
  } = pageContext

  const { archivedUsersIndexUrl, usersIndexUrl } = generateUrls()

  return (
    <>
      <Helmet>
        <title>Users | AIM Digital</title>
      </Helmet>

      <PageHeader
        activeTabBarItemKey={getActiveTabBar({ isArchived })}
        tabBarItems={[
          {
            href: usersIndexUrl,
            icon: faCheckCircle,
            key: 'active',
            title: 'Active',
          },
          {
            href: archivedUsersIndexUrl,
            icon: faBan,
            key: 'archived',
            title: 'Archived',
          },
        ]}
        title="Users"
      />

      <Box paddingX="large" paddingTop={[112, 105]}>
        <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
          <Columns.Main>
            <LoadingModule loading={loading} times={3} />

            {!loading && !hasFilteredUsers && <ListBlankState />}

            {!loading && hasFilteredUsers && (
              <DashboardModule contentBoxProps={{ flexDirection: 'column' }} title="Users">
                <Box flexDirection="column">
                  {filteredUsers.map(user => (
                    <UserListItem
                      key={user.id}
                      user={user}
                    />
                  ))}
                </Box>

                <DashboardModule.LoadMoreFooter
                  callbacks={{ loadMore }}
                  canLoadMore={canLoadMore}
                  entityCount={filteredUsersCount}
                  loading={loading}
                />
              </DashboardModule>
            )}
          </Columns.Main>

          <Columns.Sidebar>
            <PageFilterModule
              callbacks={{ resetFilters, updateFilters }}
              defaultFilters={defaultFilters}
              filterFields={buildPageFilterFields(filteredUserTypes)}
              pageFilters={pageFilters}
            />
          </Columns.Sidebar>
        </Columns>
      </Box>
    </>
  )
}

export default PageContent
