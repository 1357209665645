import { Route, Routes } from 'react-router-dom'

import Details from './screens/Details'

const UserEditRoutes = () => (
  <Routes>
    <Route index element={<Details />} />
  </Routes>
)

export default UserEditRoutes
