import { useContext } from 'react'

import {
  Button, EntityMultiSelect, Form, ModalContext, SectionDivider, SidebarModal,
} from '@campaignhub/suit-theme'

import { digObject } from '@campaignhub/javascript-utils'

import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import useBulkCreateClientUsers from './hooks/useBulkCreateClientUsers'

const MODAL_KEY = 'BulkCreateClientUsersModal'

type ModalCallbacks = {
  bulkCreateClientUsers: (payload: HandleCallbackActionParams) => void,
  closeModal: () => void,
}

type ModalProps = {
  callbacks: ModalCallbacks,
  showModal: boolean,
}

const BulkCreateClientUsersModal = (props: ModalProps) => {
  const { callbacks, showModal } = props
  const { bulkCreateClientUsers, closeModal } = callbacks || {}

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const modalPayload = digObject(modalData, MODAL_KEY, {})
  const clientUser = digObject(modalPayload, 'clientUser', {})

  const { clientId } = clientUser
  const isShowingUsers = !!clientId

  const useBulkCreateClientUsersPayload = useBulkCreateClientUsers({ clientUser })
  const {
    callbacks: {
      bulkCreateClientUsers: bulkCreateFn,
      loadMore,
      setSelectedIds,
      setState,
    },
    canLoadMore,
    creating,
    entities,
    entityName,
    entityParams,
    filteredEntityIds,
    filteredEntityIdsCount,
    loading,
    saveEnabled,
    selectedIds,
  } = useBulkCreateClientUsersPayload

  const bulkCreateClientUsersPayload = {
    callbacks: {
      action: bulkCreateFn,
      afterAction: closeModal,
    },
    entityParams,
    toastText: isShowingUsers ? 'Added Users' : 'Added Clients',
  }

  const buttonText = isShowingUsers ? 'Add Users' : 'Add Organisations'

  return (
    <SidebarModal callbacks={callbacks} clickSafeZone modalKey={MODAL_KEY} showModal={showModal}>
      <SidebarModal.Header
        callbacks={callbacks}
        title="Assign"
        titleSecondLine={isShowingUsers ? 'Users' : 'Clients'}
      />

      <SidebarModal.Content>
        <Form.Field label={isShowingUsers ? 'Search Users' : 'Search Clients'}>
          <input onChange={e => setState({ entityName: e.target.value })} value={entityName} />
        </Form.Field>

        <SectionDivider>Multi Select Values</SectionDivider>

        <EntityMultiSelect
          callbacks={{
            loadMore,
            setSelectedIds,
          }}
          canLoadMore={canLoadMore}
          entities={entities}
          entityCount={filteredEntityIdsCount}
          entityTitleKey="name"
          filteredEntityIds={filteredEntityIds}
          loading={loading}
          selectedIds={selectedIds}
        />
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={creating}
          loadingBubbleColor="white"
          onClick={() => bulkCreateClientUsers(bulkCreateClientUsersPayload)}
          size="large"
        >
          {saveEnabled ? buttonText : 'Complete Fields'}
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

const LazyLoadedModal = (props: ModalProps) => (
  <SidebarModal.RenderController {...props}>
    <BulkCreateClientUsersModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
