import { useMemo } from 'react'

import { formatDate } from '@campaignhub/javascript-utils'

import {
  StatusBadge, Table, Text,
} from '@campaignhub/suit-theme'

import getStatusBadgeProps from '@functions/statusBadge'

import useCampaigns from '@hooks/useCampaigns'

import Actions from './Actions'

const buildColumns = () => [
  {
    title: 'Campaign',
    dataKey: 'name',
    cellProps: {
      maxWidth: 250,
      verticalAlign: 'middle',
      width: 250,
    },
    render: (name: string) => (
      <Text variant="ellipsis" width={250}>
        {name}
      </Text>
    ),
  },
  {
    title: 'AIM#',
    dataKey: 'id',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
  },
  {
    title: 'Due',
    dataKey: 'requiredAt',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
    render: (dueDate: string) => (
      <StatusBadge
        boxProps={{
          backgroundColor: 'backgroundColor',
          borderColor: 'lineColor',
          color: 'black',
        }}
        text={formatDate(dueDate, 'ISO8601', 'dd/MM/yyyy')}
      />
    ),
  },
  {
    title: 'External Source',
    dataKey: 'externalPlatform',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
  },
  {
    title: 'Status',
    dataKey: 'statusId',
    cellProps: {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 40,
    },
    render: (statusId: string) => {
      const statusBadgeProps = getStatusBadgeProps(statusId)

      return (
        <StatusBadge {...statusBadgeProps} />
      )
    },
  },
  {
    title: 'Actions',
    dataKey: 'id',
    cellProps: {
      key: 'actions',
      textAlign: 'center',
      verticalAlign: 'middle',
      width: 20,
    },
    render: (campaignId: number) => <Actions campaignId={campaignId} />,
  },
]

interface OrdersTableProps {
  campaignsPayload: ReturnType<typeof useCampaigns>,
}

const OrdersTable = (props: OrdersTableProps) => {
  const {
    campaignsPayload: {
      filteredCampaigns,
      filteredCampaignsCount,
    },
  } = props

  const memoColumns = useMemo(() => buildColumns(), [filteredCampaignsCount])

  return (
    <Table
      boxProps={{ border: 'none', maxHeight: 1600, overflowY: 'auto' }}
      columns={memoColumns}
      data={filteredCampaigns}
      scroll={{ x: 800 }}
      stickyHeader
    />
  )
}

export default OrdersTable
