import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { getQueryParamsFromHash, parsePermittedQueryParams } from '@campaignhub/javascript-utils'

import { useFilters, useModals, useSetState } from '@campaignhub/react-hooks'
import type { UseFiltersPayload } from '@campaignhub/react-hooks'

import { ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import handleCallbackAction from '@functions/handleCallbackAction'
import type { HandleCallbackActionParams } from '@functions/handleCallbackAction'

import BulkUpdatePriceListsModal from '@modals/BulkUpdatePriceListsModal'
import CreatePriceListModal from '@modals/CreatePriceListModal'

import type { PriceListModel } from '@models/types'

import PageContent from './components/PageContent'

type BulkUpdatePriceListsPayload = {
  callbacks: {
    deselectAll: () => void,
  },
  priceListIds: number[],
}

export type PageContextPayload = UseFiltersPayload & {
  callbacks: {
    showBulkUpdatePriceListsModal: () => void,
    showCreatePriceListModal: () => void,
  },
}

const defaultState = {
  showBulkUpdatePriceListsModal: false,
  showCreatePriceListModal: false,
}

type SetState = (state: Partial<typeof defaultState>) => void

/* eslint-disable no-undef */
const callbacks = (component: keyof typeof componentCallbacks, setState: SetState) => {
  const componentCallbacks = {
    BulkUpdatePriceListsModal: {
      bulkUpdatePriceLists: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
      closeModal: () => setState({ showBulkUpdatePriceListsModal: false }),
    },
    CreatePriceListModal: {
      closeModal: () => setState({ showCreatePriceListModal: false }),
      createPriceList: (payload: HandleCallbackActionParams) => handleCallbackAction(payload),
    },
  }

  return componentCallbacks[component] || {}
}

const List = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    showBulkUpdatePriceListsModal,
    showCreatePriceListModal,
  } = state

  useLocation()

  const { isArchived } = parsePermittedQueryParams(getQueryParamsFromHash(), ['isArchived'])

  const filtersPayload = useFilters({
    defaultFilters: {
      isArchived,
    },
    persistFilters: true,
  })

  const {
    callbacks: filterCallbacks,
    defaultFilters,
    defaultFiltersModifiedAt,
    pageFilters,
  } = filtersPayload

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = useMemo(() => ({
    callbacks: {
      ...filterCallbacks,
      showBulkUpdatePriceListsModal: (payload: BulkUpdatePriceListsPayload) => {
        setModalData('BulkUpdatePriceListsModal', payload)
        setState({ showBulkUpdatePriceListsModal: true })
      },
      showCreatePriceListModal: (payload: { priceList: PriceListModel }) => {
        setModalData('CreatePriceListModal', payload)
        setState({ showCreatePriceListModal: true })
      },
    },
    defaultFilters,
  }), [defaultFiltersModifiedAt]) // modalContext dependency required if layering modals

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <PageContent pageFilters={pageFilters} />

        <BulkUpdatePriceListsModal
          callbacks={callbacks('BulkUpdatePriceListsModal', setState)}
          showModal={showBulkUpdatePriceListsModal}
        />

        <CreatePriceListModal
          callbacks={callbacks('CreatePriceListModal', setState)}
          showModal={showCreatePriceListModal}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default List
